import React from 'react'
import { InlineWidget } from 'react-calendly'
import classnames from 'classnames'

const ScheduleDemoSection = () => {
  return (
    <div className={classnames('container', 'col-xxl-10', 'px-4', 'py-5')}>
      <div className={classnames('row', 'align-items-center', 'text-center', 'g-5', 'py-5')}>
        <h2 className="mt-0">Let's speak</h2>
      </div>
      <div id="pricing" className={classnames('row', 'justify-content-center', 'g-5', 'd-flex')}>
        <InlineWidget
          styles={{ height: '700px' }}
          url="https://calendly.com/alex-getprobe-io/30min"
        />
      </div>
    </div>
  )
}

export default ScheduleDemoSection
